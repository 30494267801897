










































































import { Vue, Component, Watch } from 'vue-property-decorator'
import {LojaModule } from '@/store/vuex/loja/LojaStore'
import ListagemDeLojasPorCards from '@/components/loja/ListagemDeLojasPorCards.vue'
import ListagemDeLojasPorLista from '@/components/loja/ListagemDeLojasPorLista.vue'
import TelaGenerica from '@/components/layout/TelaGenerica.vue'
import { Loja, Page } from '@/models'
import axios, { CancelTokenSource } from 'axios'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { Pageable } from '@/models/Pageable'
import { FindLojaUseCase } from '@/usecases'

const OPCAO_DA_LISTAGEM_LOJA = 'OPCAO_DA_LISTAGEM_LOJA'

@Component({
	components: {
		ListagemDeLojasPorCards,
		ListagemDeLojasPorLista,
		TelaGenerica,
	},
})
export default class TelaDeLojas extends Vue {
	tipoDaListagem: 'grade' | 'lista' =
		localStorage[OPCAO_DA_LISTAGEM_LOJA] || 'grade'
	termoBusca = ''

	pagina: Page<Loja> | null = null
	paginado: Pageable = {
		page: 0,
		size: 15,
		sort: [],
	}
	cancelToken: CancelTokenSource | null = null
	carregando = false
	findLojaUseCase = new FindLojaUseCase()

	async buscar() {
		try {
			this.carregando = true
			if (this.cancelToken) this.cancelToken.cancel()
			this.cancelToken = axios.CancelToken.source()
			this.pagina = await this.findLojaUseCase.find({
				page: this.paginado.page || 0,
				size: this.paginado.size || 15,
				busca: this.termoBusca || undefined,
			},
			{
				cancelToken: this.cancelToken.token,
			})
			this.paginado.page = this.pagina.number
			LojaModule.setLojas(this.pagina.content)
		} catch (error: any) {
			if (axios.isCancel(error)) return
			this.pagina = null
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	async pageNext() {
		this.paginado = {
			...this.paginado,
			page: (this.paginado?.page || 0) + 1,
		}

		await this.buscar()
	}

	async pagePrevious() {
		this.paginado?.page
			? this.paginado.page -= 1
			: this.paginado.page = 0
		await this.buscar()
	}

	async created() {
		await this.buscar()
	}

	get lojas() {
		return LojaModule.lojasOrdenadas
	}

	get abaLateral() {
		return this.$vuetify.breakpoint.xs || this.$route.name !== 'Lojas'
	}

	@Watch('termoBusca')
	setTermo() {
		this.paginado.page = 0;
		this.buscar()
	}

	@Watch('tipoDaListagem')
	onChangeTipoDaListagem(tipo: TelaDeLojas['tipoDaListagem']) {
		localStorage[OPCAO_DA_LISTAGEM_LOJA] = tipo
	}

	get componenteDaListagem() {
		return this.tipoDaListagem === 'grade'
			? 'ListagemDeLojasPorCards'
			: 'ListagemDeLojasPorLista'
	}
}
